var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item" },
      [
        _c("signup-card", [
          _c(
            "h2",
            {
              staticClass: "title text-center",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("Register")]
          ),
          _c(
            "div",
            {
              staticClass:
                "md-layout-item md-size-50 md-medium-size-50 md-small-size-100 ml-auto",
              attrs: { slot: "content-left" },
              slot: "content-left",
            },
            _vm._l(_vm.contentLeft, function (item) {
              return _c(
                "div",
                { key: item.title, staticClass: "info info-horizontal" },
                [
                  _c(
                    "div",
                    { class: `icon ${item.colorIcon}` },
                    [_c("md-icon", [_vm._v(_vm._s(item.icon))])],
                    1
                  ),
                  _c("div", { staticClass: "description" }, [
                    _c("h4", { staticClass: "info-title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("p", { staticClass: "description" }, [
                      _vm._v(" " + _vm._s(item.description) + " "),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass:
                "md-layout-item md-size-50 md-medium-size-50 md-small-size-100 mr-auto",
              attrs: { slot: "content-right" },
              slot: "content-right",
            },
            [
              _c(
                "div",
                { staticClass: "social-line text-center" },
                [
                  _c(
                    "md-button",
                    { staticClass: "md-just-icon md-round md-twitter" },
                    [_c("i", { staticClass: "fab fa-twitter" })]
                  ),
                  _c(
                    "md-button",
                    { staticClass: "md-just-icon md-round md-dribbble" },
                    [_c("i", { staticClass: "fab fa-dribbble" })]
                  ),
                  _c(
                    "md-button",
                    { staticClass: "md-just-icon md-round md-facebook" },
                    [_c("i", { staticClass: "fab fa-facebook-f" })]
                  ),
                  _c("h4", { staticClass: "mt-3" }, [
                    _vm._v(" or be classical "),
                  ]),
                ],
                1
              ),
              _vm._l(_vm.inputs, function (item) {
                return _c(
                  "md-field",
                  { key: item.name, staticClass: "md-form-group" },
                  [
                    _c("md-icon", [_vm._v(_vm._s(item.icon))]),
                    _c("label", [_vm._v(_vm._s(item.name))]),
                    _c("md-input", {
                      attrs: { "v-model": item.nameAttr, type: item.type },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "md-checkbox",
                {
                  model: {
                    value: _vm.boolean,
                    callback: function ($$v) {
                      _vm.boolean = $$v
                    },
                    expression: "boolean",
                  },
                },
                [
                  _vm._v("I agree to the "),
                  _c("a", [_vm._v("terms and conditions")]),
                  _vm._v("."),
                ]
              ),
              _c(
                "div",
                { staticClass: "button-container" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-success md-round mt-4",
                      attrs: { slot: "footer", href: "" },
                      slot: "footer",
                    },
                    [_vm._v("Get Started")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }